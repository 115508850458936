<template>
	<div class="tic-tac-toe">
		<div class="board">
			<div v-for="(row, rowIndex) in board" :key="rowIndex" class="row">
				<transition name="cell">
					<div v-for="(cell, cellIndex) in row" :key="cellIndex" class="cell"
						:class="{ 'x': cell === 'X', 'o': cell === 'O' }" @click="handleCellClick(rowIndex, cellIndex)">
						{{ cell }}
					</div>
				</transition>
			</div>
		</div>
		<div v-if="winner || isTie" class="message">
			<div v-if="winner">{{ winner }} 赢了！</div>
			<div v-else-if="isTie">平局！</div>
			<button @click="resetGame">重新开始</button>
		</div>
		<div v-else>
			<div>当前玩家：{{ currentPlayer }}</div>
			<button @click="resetGame">重新开始</button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		const savedBoard = JSON.parse(localStorage.getItem("tic-tac-toe-board"));

		return {
			board: savedBoard || [
				["", "", ""],
				["", "", ""],
				["", "", ""]
			],
			currentPlayer: "X",
			winner: null
		};
	},
	computed: {
		isTie() {
			return this.board.every(row => {
				return row.every(cell => {
					return cell !== "";
				});
			});
		}
	},
	watch: {
		board(newBoard) {
			localStorage.setItem("tic-tac-toe-board", JSON.stringify(newBoard));
		}
	},
	methods: {
		handleCellClick(rowIndex, cellIndex) {
			if (this.board[rowIndex][cellIndex] || this.winner) return;

			this.$set(this.board[rowIndex], cellIndex, this.currentPlayer);

			if (this.checkWin()) {
				this.winner = this.currentPlayer;
			} else if (this.isTie) {
				this.winner = "tie";
			} else {
				this.currentPlayer = this.currentPlayer === "X" ? "O" : "X";
			}
		},
		checkWin() {
			const checks = [
				// 检查行
				[0, 1, 2],
				[3, 4, 5],
				[6, 7, 8],
				// 检查列
				[0, 3, 6],
				[1, 4, 7],
				[2, 5, 8],
				// 检查对角线
				[0, 4, 8],
				[2, 4, 6]
			];

			return checks.some(check => {
				const [a, b, c] = check;
				return (
					this.board[Math.floor(a / 3)][a % 3] &&
					this.board[Math.floor(a / 3)][a % 3] ===
					this.board[Math.floor(b / 3)][b % 3] &&
					this.board[Math.floor(a / 3)][a % 3] ===
					this.board[Math.floor(c / 3)][c % 3]
				);
			});
		},
		resetGame() {
			this.board = [
				["", "", ""],
				["", "", ""],
				["", "", ""]
			];
			this.currentPlayer = "X";
			this.winner = null;
		}
	}
};
</script>

<style scoped>
.tic-tac-toe {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.board {
	border: 2px solid #333;
	background-color: white;
	display: flex;
	flex-wrap: wrap;
	width: 300px;
	height: 300px;
}

.row {
	display: flex;
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 1;
	width: 100%;
	border-bottom: 2px solid #333;
}

.cell {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 3em;
	width: calc(100% / 3);
	height: calc(100% / 3);
	cursor: pointer;
}

.x {
	color: red;
}

.o {
	color: blue;
}

.message {
	margin-top: 10px;
}

button {
	margin-left: 10px;
}
</style>
