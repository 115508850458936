<template>
	<div class="article-details-box">
		<article>
			<!--顶部封面-->
			<header>
				<div class="upper-header">
					<div class="mini-title">article</div>
					<div class="date-since">
						<p><span class="date-value" id="sinceData">30分钟前</span></p>
						<svg t="1659875517934" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1496" width="20" height="20"><path fill="currentColor" d="M511.913993 63.989249c-247.012263 0-447.924744 200.912481-447.924744 447.924744s200.912481 447.924744 447.924744 447.924744 447.924744-200.912481 447.924744-447.924744S758.926256 63.989249 511.913993 63.989249zM671.199059 575.903242 480.263397 575.903242c-17.717453 0-32.166639-14.449185-32.166639-32.166639L448.096758 289.15572c0-17.717453 14.277171-31.994625 31.994625-31.994625s31.994625 14.277171 31.994625 31.994625l0 222.930287 159.285066 0c17.717453 0 31.994625 14.277171 31.994625 31.994625S688.916513 575.903242 671.199059 575.903242z" p-id="1497"></path></svg>
					</div>
				</div>
				<div class="lower-header">
					<div class="tags-container">
						<svg t="1659875243463" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1346" width="20" height="20"><path d="M995.126867 635.046667l-360.08 360.08a53.333333 53.333333 0 0 1-71.333334 3.68l356.22-356.22a64 64 0 0 0 0-90.506667L495.8402 128h45.573333a52.986667 52.986667 0 0 1 37.713334 15.62l416 416a53.4 53.4 0 0 1 0 75.426667z m-128-75.426667l-416-416A52.986667 52.986667 0 0 0 413.413533 128H338.846867a361.706667 361.706667 0 0 1 2.486666 42.666667c0 42.713333-7.266667 83.2-20.473333 114-5.146667 12-10.746667 21.546667-16.48 29.08A85.286667 85.286667 0 1 1 256.0002 298.666667c6 0 16.573333-9.64 25.646667-30.806667C292.4602 242.62 298.666867 207.193333 298.666867 170.666667a314.76 314.76 0 0 0-2.873334-42.666667H216.206867c2.82-20.533333 7.653333-39.366667 14.146666-54.526667C239.426867 52.306667 250.0002 42.666667 256.0002 42.666667s16.573333 9.64 25.646667 30.806666c6.493333 15.16 11.333333 34 14.146666 54.526667h43.053334c-3.166667-26.56-9.286667-51.046667-18-71.333333C299.733533 7.333333 271.153533 0 256.0002 0s-43.733333 7.333333-64.86 56.666667c-8.666667 20.286667-14.82 44.773333-18 71.333333H53.333533a53.393333 53.393333 0 0 0-53.333333 53.333333v360.08a52.986667 52.986667 0 0 0 15.62 37.713334l416 416a53.333333 53.333333 0 0 0 75.426667 0l360.08-360.08a53.4 53.4 0 0 0 0-75.426667z" fill="currentColor" p-id="1347"></path></svg>
						<span>散文</span><span>人生</span>
					</div>
					<h1 class="title">大雨滂沱</h1>
					<p class="subtitle">一觉醒来，城市窗外的雨声正稠。似乎有两场雨，一场在远处沙沙坠落，一场在近处啪嗒啪嗒打着窗台与地面。从窗户吹进来的阵阵凉意使我不由得伸开双手，我大口呼吸，全是泥土的味道。睡眠离我远去，整个世界开始变得陌生。</p>
				</div>
			</header>
			
			<!--文章数据-->
			<section class="summary">
				<div class="summary-item">
					<h5 class="item-title">阅读时间</h5>
					<p class="item-text"><span class="item-data">20</span> 分钟</p>
				</div>
				<div class="summary-item">
					<h5 class="item-title">浏览量</h5>
					<p class="item-text"><span class="item-data">24050</span> 阅读</p>
				</div>
				<div class="summary-item">
					<h5 class="item-title">发布时间</h5>
					<p class="item-text"><span class="item-data">八月 13, 2022</span></p>
				</div>
			</section>
			
			<!--文章详细内容-->
			<section class="main-article">
				<h4>一、梦回故乡</h4>
				<p>一觉醒来，城市窗外的雨声正稠。似乎有两场雨，一场在远处沙沙坠落，一场在近处啪嗒啪嗒打着窗台与地面。从窗户吹进来的阵阵凉意使我不由得伸开双手，我大口呼吸，全是泥土的味道。睡眠离我远去，整个世界开始变得陌生。</p>
				<p>许多年前的阴雨天里，我总莫名地兴奋，阴暗的天气使我心安理得地睡去。大雨总在夜里潜入一个孩子的梦，一定想告诉我一些道理。我没在出生的村庄长到明白它们的年龄，开始长久地远离村庄，在一场场梦里越走越远。如今，我只能想象着一场场雨使村外的庄稼迅猛生长，想象着它们粗壮的根系再一次伸长，紧紧抓住夜色下的大地。</p>
				<p>我已许久没在一场大雨中漫行或奔跑。我开始躲避它们，我以遗忘为由，变得心安理得，这往往令我进入另一个无尽的梦境。我只剩一个意念游走，意念源自一个成年人在城市的深夜里丢失的睡眠。梦将遥远的距离缩短，行路变得简单，一步就能走过多年曲折的路。路的尽头在下一场雨，雨中的村庄看不到一个人。</p>
				<p>我我推开院门，叫了一声，没人回应我，我便默默走进屋子。那张小小的木床又出现在我的面前，我感到自己并没有离开太久，眼前的一切还是想象中的模样。</p>
				<p>我躺在多年前的床上，听着窗外又开始下雨。我没理会，觉得那是一场象征性的雨，仿佛只为制造氛围。但雨声越来越大，噼噼啪啪地在窗外聒噪，我又不得不相信那是一场真正的滂沱大雨。雨声有些陈旧，像是特意响给我一个人听，竖起耳朵的我，不愿意放过任何一丝声音。我像要找一些东西，又像是无所事事，只是单纯地存在着；雨为我演奏一首音乐，这是对我长途跋涉后的褒奖。</p>
				<p>我不知道这场雨下多久，于是我决定睡去，我遇到无能为力的事情时总爱睡去，时间终究能将所有的问题解决。我相信一场梦长不过一场雨。接下来，我将开始另一场梦。</p>
				<div class="gallery">
					<div class="image-item image-1" alt="" width="200" :style="{backgroundImage: 'url(' + require('../../assets/images/article/2.jpeg') + ')'}"></div>
					<div class="image-item image-2" alt="" width="200" :style="{backgroundImage: 'url(' + require('../../assets/images/article/3.jpeg') + ')'}"></div>
					<div class="image-item image-3" alt="" width="200" :style="{backgroundImage: 'url(' + require('../../assets/images/article/4.jpeg') + ')'}"></div>
					<div class="image-item image-4" alt="" width="200" :style="{backgroundImage: 'url(' + require('../../assets/images/article/5.jpeg') + ')'}"></div>
					<div class="image-item image-5" alt="" width="200" :style="{backgroundImage: 'url(' + require('../../assets/images/article/6.jpeg') + ')'}"></div>
				</div>
				
				<h4>二、雨中行走</h4>
				<p>总是在雨后，一团亮光出现在院子的上空，有时候这团亮光出现在乌云之上，不仔细看便不易察觉。村庄周围的田野里升腾起薄雾，让人感到一丝神秘，那里一定沉睡着另一种生活。许多生命开始在雨后活跃起来。我并不厌恶阴雨连绵的日子，只需要躲在一把伞下就可以。雨中的昆虫没有雨伞，它们躲在叶子底下，从不会被雨点打得晕头转向。</p>
				<p>我早预感到大雨要来，一早便将尘封已久的雨伞取出，在屋檐下仰头驻足，像等待一件极其重要的事情。很久没下一场像样的雨了，整个大地正在口渴。我总能预感到一些在生活中即将发生的事情，我从没声张过有这项本领，一个孩子不能表现得比大人更加懂得生活，生活还远未来到一个八九岁孩子的世界中。母亲仿佛也预感到了大雨的到来，她已经好几次停下手中的活计，来到院子中仰头张望；又要远行的父亲对此并不关心，他一边抽烟一边不停地检查自己的行囊。</p>
				<p>天空一下子灰暗下来，雨开始肆无忌惮地落下，雨水在院中汇聚，又从院门下流进巷子。父亲吃完早饭，背着行囊，从院门走了出去，风随后关上了门。父亲要出远门，他似乎已经准备了很久。父亲出门时穿了一件军绿色的雨衣，雨打在上面“啪啪”直响，撞上去的雨滴反溅成许多更小的雨滴——他仿佛被一层雨雾包围。我没问他要去哪里——我本来想问，但看到他在雨中使劲低着头后便打消了这个念头。晴天的时候我与他说话也不多，这使得我以为在雨天不与他说话理所应当。</p>
				<blockquote class="pullquote">"父亲前脚出门，我后脚跟了出去。我举着伞来到村后，早已不见他的踪影，只看到了一棵站在雨中的柳树。我曾经爬上过那棵柳树，在柳枝的掩映中窥视脚下毫无察觉的路人。还是孩子的我总把自己隐藏起来，以另一种视角观察村庄的一切，这一度让我觉得自己处在另一个时空。" &mdash;  贾周章</blockquote>
				<p>几只我不认识的鸟占据了那棵柳树横生的枝杈。鸟是聪明的，从不在下雨天鸣叫，它们也明白自己的声音穿不透层层雨帘。它们正在雨中打盹儿，身子随着树枝晃动，偶尔会扇下翅膀来保持身体的平衡。</p>
				<p>雨越来越大，远处的路和田野一片模糊。我不敢走得太远，不敢走到自己摔折腿的那座废弃的石桥上。在更早的一个清早，我在那个长满青苔的石桥上滑过一跤，跛着脚走过了很长一段摇摇晃晃的时光。没人光顾的石桥，从此成了父母口中的禁忌之地。</p>
				<p>我听过老人抱怨雨天的漫长，连绵阴雨使他们唉声叹气，他们的残腿已患多年风湿，雨天他们绝不出门。他们更需要阳光，年轻时走在无数场雨中集来的寒气，到了晚年要靠阳光来一一驱逐。雨天里大多数人都不会出门，大多数院子都院门紧闭，雨天更适合临窗长眠。父亲却在雨天出门了，雨路上没有留下他的脚印。</p>
				<p>一个人影渐渐从路远处的大雨中吃力地钻出，缓缓向我走来。他穿着一件雨衣，低头弓腰使劲推着一辆自行车。每走一段距离，他便会停下来，用手里的木棍捅掉车瓦里的胶泥。他显然摔过一跤，双腿已被泥水浸透。</p>
				<p>我看着他从我身边经过，我并不认识这个中年人，但我希望他停下来歇一歇；他望了我一眼，嘴唇翕动，似乎想要与我说话——我内心一阵狂喜——但最终一个字也没说。他沿路走去，不久又消失在雨中。不久后，他会碰上一条新修的柏油路，他可能要去办一件比待在家里更重要的事情。</p>
				<p>雨还在下，村庄对它敞开胸怀，我脚下被雨伞遮蔽的一小块儿地皮成了一处显眼的漏洞。我在村庄随意行走，再也看不到父亲的身影。他已向着一个多年后我生活的城市进发。</p>
				<p>父亲离开家的时光里，很多潜伏的事情出现在我和母亲的面前。日子开始变得漫长，麦子乘机生长，热风一吹，金黄的麦浪便开始翻滚。</p>
				
				<h4>三、烈日炎炎</h4>
				<p>母亲拿出去年的镰刀，在夜晚将它磨亮，对着月亮可以晃出一道光。一块年久的磨镰石中间夸张地凹下去，宛如老者在岁月中弯下的脊背。我握着自己磨亮的小镰刀，在风中削出一串倏倏声，我跳动的脚步发出不规则的紧促的声响，趁着夜色在院子里耀武扬威。</p>
				<p>夜里，我梦到了我的父亲，他开始教我收割麦田。他弯下腰，用一只手将麦子攥住，另一只手握紧镰刀在地上一拖，刺啦一声，一把麦子应声倒地。我正准备用心学的时候，父亲扔下镰刀，跑向了一片广阔的水面。鱼不停从水面跳出来，他不停扭头转身，下手去抓时总是慢一点，鱼总能从他的指缝间漏出来。他不急不慢，换到另一个地方，重复刚才的动作。我想喊他的时候，发现自己根本喊不出声音，我只能静静地看。</p>
				<p>......</p>
				
				<h4>四、电闪雷鸣</h4>
				<p>......</p>
				
				<h4>五、遁入黑暗</h4>
				<p>......</p>
				
				<h4>六、呼风唤雨</h4>
				<p>......</p>
				
			</section>
		</article>
	</div>
	
</template>

<script>
export default {
	name: "ArticleDetailsView",
	data(){
		return {
		}
	},
	computed(){
	
	}
}
</script>

<style scoped lang="scss">


.article-details-box {
	display: flex;
	justify-content: center;
	width: 100%;
	//height: calc(max(100vh, 400px));
	background-color: rgba(16 18 27 / 10%);
	padding: 20px 40px;
	color: white;
	font-family: "Plus Jakarta Sans", sans-serif;
	overflow: auto;
	
	article {
		width: 100%;
		//max-width: 1000px;
		
		header {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 100%;
			min-height: 400px;
			padding: 50px;
			border-radius: 16px;
			color: #fff;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			background-image: url("../../assets/images/article/1.jpeg");
			
			.upper-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 20px;
				
				.mini-title {
					font-size: 1.125rem;
					font-weight: bold;
					letter-spacing: 0.4rem;
					text-transform: uppercase;
					opacity: 0.9;
				}
				
				.date-since {
					display: flex;
					align-items: center;
					opacity: 0.5;
					font-size: 0.875rem;
					
					.date-value {
						display: inline-block;
						padding-bottom: 2px;
					}
					
					svg {
						width: 20px;
						margin-left: 10px;
					}
				}
			}
			
			.lower-header {
				padding-top: 50px;
				
				.tags-container {
					display: flex;
					align-items: center;
					opacity: 0.75;
					margin-bottom: 12px;
					
					& > span:not(:nth-child(2))::before {
						content: ", ";
					}
					
					svg {
						width: 20px;
						margin-right: 10px;
					}
					
					span {
						font-size: 0.875rem;
					}
				}
				
				.title {
					margin: 20px 0;
					font-size: 3rem;
					font-weight: bold;
					opacity: 0.9;
				}
				
				.subtitle {
					width: 50%;
					margin-top: 10px;
					opacity: 0.75;
					line-height: 1.75;
				}
			}
		}
	}
	
	.summary {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 50px;
		padding: 30px 50px;
		border-radius: 16px;
		box-shadow: 0 0 0 1px #f2f2f2;
		
		.summary-item {
			width: 100%;
			padding-right: 20px;
			
			.item-title {
				color: #eae9e9;
				letter-spacing: 2px;
			}
			
			.item-text {
				margin-top: 10px;
				font-size: 1.5rem;
			}
			*{
				margin: 0;
			}
		}
	}
	
	.main-article {
		width: 100%;
		margin-top: 50px;
		padding-bottom: 50px;
		line-height: 1.75;
		
		h4 {
			margin-top: 60px;
			margin-bottom: 20px;
			font-size: 1.25em;
		}
		
		p {
			margin-bottom: 20px;
			color: #777;
			color: white;
			font-size: 1.125em;
		}
		
		blockquote {
			position: relative;
			margin: 40px 0;
			padding: 40px;
			//改为渐变背景
			background-color: #b6b6b6;
			background-color: rgba(186, 191, 217, 0.4);
			
			border-radius: 16px;
			
			&::before {
				content: url("https://icons.craftwork.design/static/media/QuotesFill.f65b03951f44e212816420b00909f4df.svg");
				position: absolute;
				top: -10px;
				left: 0px;
				transform: scale(2);
				opacity: 0.1;
			}
			
			&::after {
				content: url("https://icons.craftwork.design/static/media/QuotesFill.f65b03951f44e212816420b00909f4df.svg");
				position: absolute;
				bottom: -10px;
				right: 0px;
				transform: scale(2) rotate(180deg);
				opacity: 0.1;
			}
		}
		
		.gallery {
			display: grid;
			gap: 20px;
			grid-template-areas:
				"img1 img1 img2 img3"
				"img1 img1 img4 img5";
			min-height: 400px;
			margin-top: 40px;
			
			.image-item {
				min-height: 200px;
				background-color: #eee;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: 8px;
				transition: 250ms;
				
				&:hover {
					opacity: 0.9;
					cursor: pointer;
				}
			}
			
			.image-1 {
				grid-area: img1;
			}
			.image-2 {
				grid-area: img2;
			}
			.image-3 {
				grid-area: img3;
			}
			.image-4 {
				grid-area: img4;
			}
			.image-5 {
				grid-area: img5;
			}
			
		}
	}
}

@media screen and (max-width: 1024px) {
	.article-details-box {
		padding: 20px;
		
		article header .lower-header .subtitle {
			width: 100%;
		}
	}
}

@media screen and (max-width: 768px) {
	.article-details-box article {
		header .lower-header .title {
			word-break: break-all;
		}
		
		.summary {
			flex-direction: column;
			
			.summary-item:not(:last-child) {
				padding-bottom: 30px;
			}
		}
		
		.main-article {
			.gallery {
				grid-template-areas:
					"img1 img1"
					"img1 img1"
					"img2 img3"
					"img4 img5";
			}
		}
	}
}

@media screen and (max-width: 425px) {
	.article-details-box {
		padding: 20px;
	}
	
	.article-details-box article header .upper-header .date-since {
		display: none;
	}
}


</style>